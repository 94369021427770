import Lottie from 'lottie-react-web';
import { useMemo } from 'react';

import loadingAnimation from '../data/lottie/loading.json';
import { useConfig } from '../lib/hooks';
import { changeColors } from '../lib/lottie';

function LargeSpinner() {
    let config = useConfig();
    let json = useMemo(() => changeColors(loadingAnimation, config.ui.colors.primary), []);

    return (
        <div className="w-32 h-32">
            <Lottie
                options={{
                    animationData: json,
                    loop: true
                }}
            />
        </div>
    );
}

export default LargeSpinner;
