import tinycolor from 'tinycolor2';

const DEFAULT_LOTTIE_PRIMARY = '#fec600';

export function changeColors<T>(json: T, primaryColor: string) {
    let result = JSON.stringify(json);

    if (primaryColor !== DEFAULT_LOTTIE_PRIMARY) {
        let color1 = tinycolor.mix(primaryColor, '#fff', 60).toRgb();
        let color2 = tinycolor(primaryColor).toRgb();
        let color3 = tinycolor.mix(primaryColor, '#000', 10).toRgb();
        let color4 = tinycolor.mix(primaryColor, '#000', 30).toRgb();

        result = result.replace(/\[1,\s*0\.9098\d+,\s*0\.5843\d+,/g, `[${color1.r / 255},${color1.g / 255},${color1.b / 255},`);
        result = result.replace(/\[0\.9960\d+,\s*0\.7764\d+,\s*0,/g, `[${color2.r / 255},${color2.g / 255},${color2.b / 255},`);
        result = result.replace(/\[0\.9960\d+,\s*0\.6796\d+,\s*0,/g, `[${color3.r / 255},${color3.g / 255},${color3.b / 255},`);
        result = result.replace(/\[0\.9960\d+,\s*0\.5859\d+,\s*0,/g, `[${color4.r / 255},${color4.g / 255},${color4.b / 255},`);
    }

    return JSON.parse(result);
}
